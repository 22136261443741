import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import axios from 'axios';
import { endpoint, refresh } from './constants/constant';

const Layout = () => {
    axios.defaults.withCredentials = true;

    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Tracks authentication status

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get(`${endpoint}${refresh}`);
                const token = response.data.accessToken;

                // Decode token
                const base64Url = token?.split(".")[1];
                const base64 = base64Url?.replace("-", "+").replace("_", "/");
                const tokenDataWithoutToken = JSON.parse(window?.atob(base64));

                const tokenData = {
                    username: tokenDataWithoutToken.username,
                    role: tokenDataWithoutToken.role,
                    rawToken: token,
                };

                // Update auth context and authentication status
                if (tokenData.role) {
                    setAuth(tokenData);
                    setIsAuthenticated(true);
                    navigate(location.pathname); // Redirect to the same path to reinitialize
                } else {
                    console.log("Role not found");
                }
            } catch (error) {
                console.error("Error during authentication:", error);
            }
        };

        // Only check authentication if the role is not available
        if (!auth.role) {
            checkAuthentication();
        }
    }, []);

    return (
        <div>
            {/* Conditional rendering to ensure updates */}
             <Outlet /> 
        </div>
    );
};

export default Layout;
