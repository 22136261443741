import React, { useState } from 'react'
import './Dashboard.css'
import AdminNav from '../Nav/AdminNav'

import './Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../hooks/useAuth';

import { Link } from 'react-router-dom';
import { endpoint, getallforms } from '../../constants/constant'


const PresidentDash = () => {

    const [showNewApprovals, setShowNewApprovals] = useState(true)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    // console.log(auth);

    useEffect(() => {
        console.log(auth);
        const token = auth.rawToken;
        const getformdata = async () => {

            try {
                const response = await axios.get(`${endpoint}${getallforms}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                })

                console.log(response.data)
                setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        getformdata();
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }
    const [searchCaseId, setSearchCaseId] = useState("");

    const [filters, setFilters] = useState({
            all: true, // Default filter
            clerk: false,
            accountant: false,
        });

    const toggleFilter = (filter) => {
        if (filter === 'all') {
            setFilters({ all: true, clerk: false, accountant: false });
        } else {
            setFilters((prev) => ({
                ...prev,
                all: false,
                [filter]: !prev[filter],
            }));
        }
    };
    
    const filteredApprovals = Approvals
    // .filter((item) => {
    //     if (filters.all) return true;

    //     const filterConditions = [];
    //     if (filters.clerk) filterConditions.push(item.approvals.clerk === true);
    //     if (filters.accountant) filterConditions.push(item.approvals.accountant === true);
    //     if (filters.registrar) filterConditions.push(item.approvals.registrar === true);

    //     return filterConditions.length > 0 ? filterConditions.some(Boolean) : true;
    // })
    .filter((item) =>
        item.approvals.president === false &&
        item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
    )
    .sort((a, b) => {
        const aNum = parseInt(a.CRN.match(/\d+/)?.[0] || 0, 10);
        const bNum = parseInt(b.CRN.match(/\d+/)?.[0] || 0, 10);
        return bNum - aNum;
    });

    console.log('filteredApprovals',filteredApprovals)
    return (
        <div className=''>
            <AdminNav />

            <div className='Dashboard'>
                <div className='sideBar'>
                    <div className={showNewApprovals ? 'selected sidebarItem' : 'sidebarItem'}
                        onClick={() => { setShowNewApprovals(true); setShowPrevApprovals(false) }}>
                        New Applications
                    </div>
                    <div className={showPrevApprovals ? 'selected sidebarItem' : 'sidebarItem'}
                        onClick={() => { setShowPrevApprovals(true); setShowNewApprovals(false) }}>
                        Previous Approved Applications
                    </div>
                </div>

                <div className='mainBar'>
                    <div className='dashMainHeading'>President Dashboard</div>
                    <div className="searchBox">
                        <input
                            type="text"
                            placeholder="Search by Case ID..."
                            value={searchCaseId}
                            onChange={(e) => setSearchCaseId(e.target.value)}
                            className="searchInput"
                        />
                    </div>
                    {/* {!viewNewApprovalentry?         */}
                    {showNewApprovals ?
                        <>
                            <div className='dashHeading'>New Applications</div>

                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ?
                                    (filteredApprovals
                                        .map((item, i) => (
                                            <div key={i} className='approval'>
                                                <div className='approvalContent'>
                                                    <div><strong>Organization Name:</strong> {item.organizationName}</div>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <div><strong>Theme:</strong> {item.theme}</div>
                                                    <div><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</div>
                                                </div>

                                                <Link to={`/dashboard/president/${item._id}`} key={item._id}>
                                                    <button>
                                                        View
                                                    </button>
                                                </Link>

                                            </div>
                                        ))
                                    )
                                    :
                                    <div>No records available</div>
                                }

                            </div>

                        </>

                        :
                        <>
                            <div className='dashHeading'>Previous Approvals</div>

                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ?
                                    (Approvals
                                        .filter((item) => item.approvals.president === true &&



                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                        )



                                        .map((item, i) => (
                                            <div key={i} className='approval'>
                                                <div className='approvalContent'>
                                                    <div><strong>Organization Name:</strong> {item.organizationName}</div>

                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <div><strong>Theme:</strong> {item.theme}</div>


                                                    <div><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</div>
                                                </div>

                                                <Link to={`/dashboard/president/${item._id}`} key={item._id}>
                                                    <button>
                                                        View
                                                    </button>
                                                </Link>

                                            </div>
                                        ))
                                    )
                                    :
                                    <div>No records available</div>
                                }

                            </div>
                        </>
                    }

                   


                </div>
            </div>
        </div>
    )
}

export default PresidentDash