import React, { useState } from 'react'
import '../Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav'
import { Link } from 'react-router-dom';
import { endpoint, getallforms } from '../../../constants/constant';


const RegistrarDash = () => {


    const [showNewApprovals, setShowNewApprovals] = useState(true)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    // console.log(auth);

    useEffect(() => {
        console.log(auth);
        const token = auth.rawToken;
        const getformdata = async () => {

            try {
                const response = await axios.get(`${endpoint}${getallforms}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                })

                console.log(response.data)
                setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        getformdata();
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }
    const [searchCaseId, setSearchCaseId] = useState("");

    const [filters, setFilters] = useState({
        all: true, // Default filter
        clerk: false,
        accountant: false,
    });

    const toggleFilter = (filter) => {
        if (filter === 'all') {
            setFilters({ all: true, clerk: false, accountant: false });
        } else {
            setFilters((prev) => ({
                ...prev,
                all: false,
                [filter]: !prev[filter],
            }));
        }
    };
    
    const filteredApprovals = Approvals
    .filter((item) => {
        if (filters.all) return true;

        const filterConditions = [];
        if (filters.clerk) filterConditions.push(item.approvals.clerk === true);
        if (filters.accountant) filterConditions.push(item.approvals.accountant === true);

        return filterConditions.length > 0 ? filterConditions.some(Boolean) : true;
    })
    .filter((item) =>
        item.approvals.registrar === false &&
        item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
    )
    .sort((a, b) => {
        const aNum = parseInt(a.CRN.match(/\d+/)?.[0] || 0, 10);
        const bNum = parseInt(b.CRN.match(/\d+/)?.[0] || 0, 10);
        return bNum - aNum;
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <AdminNav />

            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{
                    width: '240px',
                    backgroundColor: '#031d60', // Dark blue background for sidebar
                    color: 'white',
                    padding: '20px'
                }}>
                    <div
                        style={{
                            padding: '15px',
                            cursor: 'pointer',
                            backgroundColor: showNewApprovals ? '#27ae60' : 'transparent', // Green for selected item
                            transition: 'background-color 0.3s'
                        }}
                        onClick={() => { setShowNewApprovals(true); setShowPrevApprovals(false) }}>
                        New Applications
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            cursor: 'pointer',
                            backgroundColor: showPrevApprovals ? '#27ae60' : 'transparent', // Green for selected item
                            transition: 'background-color 0.3s'
                        }}
                        onClick={() => { setShowPrevApprovals(true); setShowNewApprovals(false) }}>
                        Previously Approved
                    </div>
                </div>

                <div style={{ flex: 1, padding: '20px', backgroundColor: '#ECF0F1' }}>
                    <h1 style={{ fontSize: '32px', color: '#2C3E50', marginBottom: '20px' }}>Registrar Dashboard</h1>
                    <div className="searchBox">
                        <input
                            type="text"
                            placeholder="Search by Case ID..."
                            value={searchCaseId}
                            onChange={(e) => setSearchCaseId(e.target.value)}
                            className="searchInput"
                        />
                    </div>
                    {/* {showNewApprovals ? (
                        <>
                            <h2 style={{ fontSize: '24px', color: '#2C3E50', marginBottom: '10px' }}>New Applications</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals
                                        .filter((item) => item.approvals.registrar === false

                                            && item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                        )
                                        .map((item, i) => (
                                            <div key={i} style={{
                                                backgroundColor: 'white',
                                                padding: '15px',
                                                borderRadius: '8px',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ marginBottom: '15px' }}>
                                                    <p><strong>Organization Name:</strong> {item.organizationName}</p>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <p><strong>Theme:</strong> {item.theme}</p>
                                                    <p><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</p>
                                                </div>
                                                <Link to={`/dashboard/registrar/${item._id}`} key={item._id}>
                                                    <button style={{
                                                        padding: '10px 15px',
                                                        backgroundColor: '#2ecc71', // Green button
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s'
                                                    }}>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <p>No records available</p>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 style={{ fontSize: '24px', color: '#2C3E50', marginBottom: '10px' }}>Previously Approved</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals
                                        .filter((item) => item.approvals.registrar === true
                                            && item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())

                                        )
                                        .map((item, i) => (
                                            <div key={i} style={{
                                                backgroundColor: 'white',
                                                padding: '15px',
                                                borderRadius: '8px',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ marginBottom: '15px' }}>
                                                    <p><strong>Organization Name:</strong> {item.organizationName}</p>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <p><strong>Theme:</strong> {item.theme}</p>
                                                    <p><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</p>
                                                </div>
                                                <Link to={`/dashboard/registrar/${item._id}`} key={item._id}>
                                                    <button style={{
                                                        padding: '10px 15px',
                                                        backgroundColor: '#2ecc71', // Green button
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s'
                                                    }}>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <p>No records available</p>
                                )}
                            </div>
                        </>
                    )} */}
                    {showNewApprovals ? (
                        <>
                            <h2 style={{ fontSize: '24px', color: '#2C3E50', marginBottom: '10px' }}>New Applications</h2>
                            <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                                <button
                                    style={{
                                        padding: '10px 15px',
                                        backgroundColor: filters.all ? '#3498db' : '#ecf0f1',
                                        color: filters.all ? 'white' : '#2C3E50',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleFilter('all')}
                                >
                                    All Applications
                                </button>
                                <button
                                    style={{
                                        padding: '10px 15px',
                                        backgroundColor: filters.clerk ? '#3498db' : '#ecf0f1',
                                        color: filters.clerk ? 'white' : '#2C3E50',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleFilter('clerk')}
                                >
                                    Approved by Clerk
                                </button>
                                <button
                                    style={{
                                        padding: '10px 15px',
                                        backgroundColor: filters.accountant ? '#3498db' : '#ecf0f1',
                                        color: filters.accountant ? 'white' : '#2C3E50',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleFilter('accountant')}
                                >
                                    Approved by Accountant
                                </button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {filteredApprovals && filteredApprovals.length > 0 ? (
                                    filteredApprovals
                                        // .filter((item) =>
                                        //     item.approvals.registrar === false &&
                                        //     item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                        // )
                                        // .sort((a, b) => {
                                        //     const aNum = parseInt(a.CRN.match(/\d+/)?.[0] || 0, 10);
                                        //     const bNum = parseInt(b.CRN.match(/\d+/)?.[0] || 0, 10);
                                        //     return bNum - aNum; // Descending sort by numeric value in CRN
                                        // })
                                        .map((item, i) => (
                                            <div key={i} style={{
                                                backgroundColor: 'white',
                                                padding: '15px',
                                                borderRadius: '8px',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ marginBottom: '15px' }}>
                                                    <p><strong>Organization Name:</strong> {item.organizationName}</p>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <p><strong>Theme:</strong> {item.theme}</p>
                                                    <p><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</p>
                                                </div>
                                                <Link to={`/dashboard/registrar/${item._id}`} key={item._id}>
                                                    <button style={{
                                                        padding: '10px 15px',
                                                        backgroundColor: '#2ecc71', // Green button
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s'
                                                    }}>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <p>No records available</p>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 style={{ fontSize: '24px', color: '#2C3E50', marginBottom: '10px' }}>Previously Approved</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals
                                        .filter((item) =>
                                            item.approvals.registrar === true &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                        )
                                        .sort((a, b) => {
                                            const aNum = parseInt(a.CRN.match(/\d+/)?.[0] || 0, 10);
                                            const bNum = parseInt(b.CRN.match(/\d+/)?.[0] || 0, 10);
                                            return bNum - aNum; // Descending sort by numeric value in CRN
                                        })
                                        .map((item, i) => (
                                            <div key={i} style={{
                                                backgroundColor: 'white',
                                                padding: '15px',
                                                borderRadius: '8px',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div style={{ marginBottom: '15px' }}>
                                                    <p><strong>Organization Name:</strong> {item.organizationName}</p>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <p><strong>Theme:</strong> {item.theme}</p>
                                                    <p><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</p>
                                                </div>
                                                <Link to={`/dashboard/registrar/${item._id}`} key={item._id}>
                                                    <button style={{
                                                        padding: '10px 15px',
                                                        backgroundColor: '#2ecc71', // Green button
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s'
                                                    }}>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <p>No records available</p>
                                )}
                            </div>
                        </>
                    )}


                </div>
            </div>
        </div>



    )
}

export default RegistrarDash