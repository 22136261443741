import React, { useState } from 'react'
import './Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../hooks/useAuth';
import AdminNav from '../Nav/AdminNav'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { endpoint, getformdata, Presidentapproval } from '../../constants/constant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const predefinedLabels = [
    'Registration Number of Association / Organization with evidence',
    'List of the Faculty with detailed qualification and experience',
    'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    'List of Topics',
    'Medical Registration Certificate of the Organizing Chairman',
    'Medical Registration Certificate of the Organizing Secretary',
    'Any Other Supporting Documents'
];

const observers = [
    {
        name: "Dr. Manish Bansal",
        designation: "President, HMC",
        mobile: "9996342422",
        email: "manishsujata@gmail.com"
    },
    {
        name: "Dr. Pankaj Mutneja",
        designation: "Vice President, HMC",
        mobile: "9812030191",
        email: "drpankaj@drpremhospital.com"
    },
    {
        name: "Dr. Satish Khattar",
        designation: "Member, HMC",
        mobile: "9996002814",
        email: "drskkhattar@gmail.com"
    },
    {
        name: "Dr. Ashok Garg",
        designation: "Member, HMC",
        mobile: "9812029343",
        email: "drashokgarg52@gmail.com"
    },
    {
        name: "Dr. Parveen K. Garg",
        designation: "Ex. DGHS & Ex. Member, HMC",
        mobile: "9416042844",
        email: "drparveenkarg@gmail.com"
    },
    {
        name: "Dr. Rosy Aneja",
        designation: "Member, HMC",
        mobile: "9416028186",
        email: "dr.rkaneja@gmail.com"
    },
    {
        name: "Dr. Mandeep Sachdeva",
        designation: "Registrar, HMC",
        mobile: "9802361100",
        email: "mandeepdoctor@gmail.com"
    },
    {
        name: "Mr. Dushyant Sharma",
        designation: "IT Assistant, HMC",
        mobile: "8568000112",
        email: "Clerk3.hmc@gmail.com"
    },
    {
        name: "Dr. Ravish aneja",
        designation: "HMC Representative",
        mobile: "9996928186",
        email: "ravishaneja@gmail.com"
    }
];


const PresidentApprovalPage = () => {
    //this page is used for form approvals
    const [showNewApprovals, setShowNewApprovals] = useState(true)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const [formData, setformData] = useState({})
    const [token, settoken] = useState()
    // console.log(auth);
    const navigate = useNavigate();
    const { _id } = useParams()

    // var token='';
    const [cmePoints, setCmePoints] = useState("");

    useEffect(() => {
        console.log(auth);
        const tokendata = auth.rawToken;

        settoken(e => e = tokendata)



        const handlegetformdata = async () => {



            try {

                const response = await axios.get(`${endpoint}${getformdata}?id=${_id}`, {
                    headers: {
                        'Authorization': `Bearer ${tokendata}`,
                        'Accept': 'application/json'
                    }
                })
                console.log(response.data)
                const fd = response.data;
                // console.log(fd[0].chairman)
                setformData(e => fd[0])
                console.log(formData)
                // setApprovals(response.data)

            } catch (e) { console.log(e) }
        }


        const timer = setTimeout(() => {
            handlegetformdata();
        }, 1500); // Delay by 2 seconds
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }

    const approveCME = async (e) => {
        // Validate that observer and points are provided
        if (!selectedObserver) {
            alert("Please select an observer.");
            return;
        }
        if (!cmePoints) {
            alert("Please add CME points.");
            return;
        }

        console.log(_id, selectedObserver, cmePoints);

        try {
            const response = await axios.post(
                `${endpoint}${Presidentapproval}`,
                {
                    _id,
                    observer: selectedObserver, // Adding the observer details
                    cmePoints, // Adding the CME points
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token
                    }
                }
            );
            setformData(e => response.data)
            console.log(response.data);
            // Additional handling of response here if needed
        } catch (error) {
            console.error(error);
        }
    };


    const [selectedObserver, setSelectedObserver] = useState(null);
    const handleObserverChange = (e) => {
        const selected = observers.find(observer => observer.name === e.target.value);
        setSelectedObserver(selected);
    };
    const handleCmePointsChange = (e) => {
        setCmePoints(e.target.value);
    };

    const handleReappointClick = () => {
        setObserverDropdownShow(true);
        setShowReappointButton(false); // Hide the button after clicking
    };


    // };
    const [showReappointButton, setShowReappointButton] = useState(true);


    const assignNew = () => {

        console.log(selectedObserver, cmePoints)
    }

    const [observerDropdownShow, setObserverDropdownShow] = useState(false)

    const handleBackClick = () => {
        const isFreshLoad = window.performance.getEntriesByType("navigation")[0]?.type === "reload";

        if (!isFreshLoad && window.history.state && window.history.state.idx > 0) {
            console.log("Navigating back");
            navigate(-1); // Go back in history
        } else {
            console.log("Navigating to fallback URL");
            navigate("/dashboard/new"); // Navigate to fallback route
        }

    };

    return (
        <div className=''>
            <AdminNav />

            <div className='Dashboard'>

                <div className='mainBar approvalPage'>
                    <div className='dashMainHeading'>President Dashboard</div>

                    <div className='dashHeadingFlex'>
                        <div className='dashHeading'>FORM DATA</div>
                        <div className='backBtn'

                            onClick={() => {
                                handleBackClick()

                            }}

                        >
                            <ArrowBackIcon />
                            Back to All Records
                        </div>
                    </div>




                    <div className='formDataDiv'>

                        <div className='formDatagrid'>
                            <div><strong>Organization Name: </strong>{formData?.organizationName}</div>
                            <div><strong>Email: </strong>{formData?.email}</div>
                            <div><strong>Address: </strong>{formData?.address}</div>
                            <div><strong>Theme: </strong>{formData?.theme}</div>
                            <div><strong>Registration Number: </strong>{formData?.registrationNumber}</div>
                            <div><strong>CME Start Date: </strong>{formData?.cmeStartDate}</div>
                            <div><strong>CME End Date: </strong>{formData?.cmeEndDate}</div>
                            <div><strong>No of Days: </strong>{formData?.daysDifference}</div>
                            <div><strong>Faculty 1: </strong>{formData?.faculty1}</div>
                            <div><strong>No of Delegates: </strong>{formData?.delegates}</div>
                            <div><strong>No of Exercise: </strong>{formData?.exercise}</div>
                            <div><strong>No of Hours: </strong>{formData?.hours}</div>
                            <div><strong>Chairman: </strong>{formData?.chairman}</div>
                            <div><strong>Secretary: </strong>{formData?.secretary}</div>
                        </div>

                        <div className='pdfHead'>
                            List of Documents:
                        </div>

                        <div>
                            {predefinedLabels?.map((label, index) => {
                                // Check if the label from predefinedLabels matches the PDF_Label in formData.pdfURL
                                const matchingPDF = formData?.pdfURL?.find(pdf => pdf.PDF_Label === label);

                                return (
                                    <div key={index} className='viewPDFflex'>
                                        <div className='viewpdfSno'>
                                            {index + 1}
                                        </div>

                                        {/* Label display */}
                                        <div className='viewpdfLabel'>
                                            {label}
                                        </div>

                                        {/* View and Approve buttons if a match is found */}
                                        {matchingPDF && matchingPDF.url ? (
                                            <>
                                                {/* View button */}
                                                <a href={matchingPDF.url} className="viewPDFviewBtn" target="_blank" rel="noopener noreferrer">
                                                    <button>View</button>
                                                </a>

                                                {/* Approve button */}
                                                {!matchingPDF.approved ? (
                                                    <div className='viewPDFviewBtn'>
                                                        {/* <button
                                                            className='approveBtn'
                                                            id={matchingPDF.fileName}
                                                            // onClick={(e) => handleapprove(e)}
                                                        >
                                                            Approve
                                                        </button> */}
                                                    </div>
                                                ) : (
                                                    <div className='viewPDFviewBtn'>
                                                        <button
                                                            className='approveBtn approved'
                                                        >
                                                            Approved
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className='viewPDFviewBtn'></div>
                                                <div className='viewPDFviewBtn'></div>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        {formData?.approvals?.president ? <></> :
                            <div className='presidentObserverDiv'>
                                <div>
                                    <label htmlFor="observer-select">Select an Observer: </label>
                                    <select id="observer-select" onChange={handleObserverChange} defaultValue="" className='presidentInput'>
                                        <option value="" disabled>
                                            -- Choose an Observer --
                                        </option>
                                        {observers.map((observer, index) => (
                                            <option key={index} value={observer.name}>
                                                {observer.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <button onClick={handleObserverSubmit}>Submit</button> */}
                                </div>

                                <div>
                                    <label htmlFor="cme-points">Enter CME Points: </label>
                                    <input
                                        type="number"
                                        id="cme-points" className='presidentInput'
                                        value={cmePoints}
                                        onChange={handleCmePointsChange}
                                        placeholder="Enter CME points"
                                    />
                                </div>
                            </div>}

                        {formData?.approvals?.president ? (
                            <>
                                <div className="approvedLabel" style={{ marginTop: '24px' }}>
                                    You have already Approved!
                                </div>
                                {showReappointButton && (
                                    <button
                                        onClick={handleReappointClick}
                                        className="approveBtn"
                                        style={{ marginTop: '24px' }}
                                    >
                                        Reappoint Observer
                                    </button>
                                )}
                            </>
                        ) : (
                            <button
                                onClick={() => approveCME()}
                                className="approveBtn"
                                style={{ marginTop: '24px' }}
                            >
                                Approve CME
                            </button>
                        )}



                        {observerDropdownShow ? <>
                            <div style={{ minHeight: "100px" }}></div>
                            <div>
                                <label htmlFor="observer-select">Select an Observer: </label>
                                <select id="observer-select" onChange={handleObserverChange} defaultValue="" className='presidentInput'>
                                    <option value="" disabled>
                                        -- Choose an Observer --
                                    </option>
                                    {observers.map((observer, index) => (
                                        <option key={index} value={observer.name}>
                                            {observer.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ marginTop: '1rem' }}>
                                <label htmlFor="cme-points">Enter CME Points: </label>
                                <input
                                    type="number"
                                    id="cme-points"
                                    className="presidentInput"
                                    value={cmePoints}
                                    onChange={handleCmePointsChange}
                                    placeholder="Enter CME points"
                                    style={{ marginLeft: '0.5rem' }} // Adds left margin to the input
                                />
                            </div>

                            <button
                                onClick={() => { approveCME() }}
                                className='approveBtn' style={{ marginTop: '24px' }}
                            >
                                Submit New Observer & Points
                            </button>

                        </> : <></>}


                        <div className='pdfHead'>
                            Approval Status:
                        </div>
                        <div>

                            <div className='StatusGrid'>
                                <strong>Clerk:</strong> {formData?.approvals?.clerk ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>
                            <div className='StatusGrid'>
                                <strong>Accountant:</strong> {formData?.approvals?.accountant ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>



                            <div className='StatusGrid'>
                                <strong>Registrar:</strong> {formData?.approvals?.registrar ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>

                            <div className='StatusGrid'>
                                <strong>President:</strong> {formData?.approvals?.president ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>
                        </div>


                        {/* {formData?.approvals?.clerk ?

    <button className='approveBtn' style={{ marginTop: '24px' }}
        onClick={generateCertificate}>
        Generate Certificate
    </button>
    :
    null
} */}

                    </div>


                </div>
            </div>
        </div>
    )
}

export default PresidentApprovalPage