import React, { useState, useEffect } from 'react';
import '.././Dashboard.css';
// import Nav from '../Nav/Nav'
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav';
import { Link, useLocation } from 'react-router-dom';
import { endpoint, getallforms } from '../../../constants/constant';

const AccountantDash = () => {
    const [showNewApprovals, setShowNewApprovals] = useState(false);
    const [showPrevApprovals, setShowPrevApprovals] = useState(false);
    const [Approvals, setApprovals] = useState([]);
    const { auth } = useAuth();
    const location = useLocation();
    const [token, setToken] = useState();

    useEffect(() => {
        const token = auth.rawToken;
        setToken(token);

        const fetchData = async () => {
            try {
                const response = await axios.get(`${endpoint}${getallforms}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                    },
                });
                setApprovals(response.data);
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();
    }, [auth]);

    useEffect(() => {
        setShowNewApprovals(false);
        setShowPrevApprovals(false);

        const pathAfterDashboard = location.pathname.split('/dashboard/')[1];
        if (pathAfterDashboard === 'new') {
            setShowNewApprovals(true);
        } else if (pathAfterDashboard === 'previous') {
            setShowPrevApprovals(true);
        }
    }, [location.pathname]);
    const [searchCaseId, setSearchCaseId] = useState("");

    return (
        <div>
            <AdminNav />
            <div className="Dashboard">
                <div className="sideBar">
                    <Link
                        to={{
                            pathname: '/dashboard/new',
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === '/dashboard/new' ? 'selected sidebarItem' : 'sidebarItem'}
                    >
                        New Applications
                    </Link>

                    <Link
                        to={{
                            pathname: '/dashboard/previous',
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === '/dashboard/previous' ? 'selected sidebarItem' : 'sidebarItem'}
                    >
                        Previously Approved
                    </Link>
                </div>

                <div className="mainBar">
                    <div className="dashMainHeading">Accountant Dashboard</div>
                    <div className="searchBox">
                        <input
                            type="text"
                            placeholder="Search by Case ID..."
                            value={searchCaseId}
                            onChange={(e) => setSearchCaseId(e.target.value)}
                            className="searchInput"
                        />
                    </div>
                    {showNewApprovals && (
                        <>
                            <div className="dashHeading">New Applications</div>
                            <div className="approvalsContainer">
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter((item) => item.approvals.accountant === false &&
                                        item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong> {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}
                                                </div>
                                            </div>

                                            <Link to={`/dashboard/accountant/${item._id}`} key={item._id}>
                                                <button>View</button>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}
                            </div>
                        </>
                    )}

                    {showPrevApprovals && (
                        <>
                            <div className="dashHeading">Previous Approvals</div>


                            <div className="approvalsContainer">
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter((item) => item.approvals.accountant === true &&
                                        item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()))
                                        .map((item, i) => (
                                            <div key={i} className="approval">
                                                <div className="approvalContent">
                                                    <div>
                                                        <strong>Organization Name:</strong> {item.organizationName}
                                                    </div>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <div>
                                                        <strong>Theme:</strong> {item.theme}
                                                    </div>
                                                    <div>
                                                        <strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}
                                                    </div>
                                                </div>

                                                <Link to={`/dashboard/accountant/${item._id}`} key={item._id}>
                                                    <button>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <div>No records available</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountantDash;
